import React from "react";
import Layout from "../../components/Layout";
import SEO from "../../components/Seo";
import MainFrontpage from "../../components/MainFrontpage";
import SeoLeftColumn from "../../components/SeoLeftColumn";
import SeoRightColumn from "../../components/SeoRightColumn";
import { useAlternateLangs } from "../../components/Hreflangs";

// 1. define Hreflang data, for href tags and language button
// Hreflang data
const alternateLangs = useAlternateLangs(
  // replace with pages x-default
  "/en/seo"
);


const Seo = ({ location }) => { // Receive location as a prop
  return (
    <Layout location={location} alternateLangs={alternateLangs}>
      <React.Fragment>
        {/* 2. define SEO meta data */}
        <SEO
          title="Opplæringsprogrammer om SEO & SEO-verktøy"
          lang="no"
          description="Forbedre dine SEO-ferdigheter med opplæringsprogrammer om SEO og DIY SEO-verktøy. Få mesterskap i søkeordforskning, on-page optimalisering, og mer med våre trinnvise guider."
          image="homepage-screenshot"
          alternateLangs={alternateLangs}
        // canonical="/no"
        />
        <MainFrontpage>
          <SeoLeftColumn />
          <SeoRightColumn />
        </MainFrontpage>
      </React.Fragment>
    </Layout>
  );
};

export default React.memo(Seo);
